'use client';
import { appPublicConfig } from '@/configs/appPublicConfig';
import { ButtonLinkPrimary } from '@/shared/components/features/buttons/ButtonLinkPrimary';
import { ButtonLinkSecondary } from '@/shared/components/features/buttons/ButtonLinkSecondary';
import { Spacer } from '@/shared/components/parts/Spacer';
import { useSiteTop } from './idex.hook';

export const SiteTop: React.FC = () => {
  const { isInitialized } = useSiteTop();

  return (
    <>
      {isInitialized && (
        <>
          <section className="tw-w-full-space tw-mt-[4rem] tw-max-w-[40rem] laptop:tw-max-w-[60rem]">
            <div className="tw-grid tw-grid-cols-1 tw-gap-[2.5rem] laptop:tw-grid-cols-2 laptop:tw-items-center">
              <div>
                <div className="tw-relative tw-mx-auto tw-aspect-[1/1] tw-w-full tw-max-w-[30rem]">
                  <div className="tw-absolute tw-bottom-0 tw-right-0 tw-aspect-[1/1] tw-w-[calc(100%-2rem)] tw-bg-brand-lv3" />
                  <div className="tw-absolute tw-left-0  tw-top-0 tw-aspect-[1/1] tw-w-[calc(100%-2rem)] tw-bg-brand-lv3">
                    <img
                      src={`/images/top/cover${Math.floor(
                        Math.random() * 3
                      )}.jpg`}
                      alt="HELLO ERO(ハローエロ)"
                      className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover"
                    />
                  </div>
                </div>
              </div>
              <div>
                <h2 className="tw-text-h1">
                  シンプルに、カジュアルに。アダルトコンテンツを楽しもう。
                </h2>
                <Spacer level={1.5} />
                <p>
                  {
                    'ようこそ、HELLO ERO(ハローエロ)へ。さあ、今すぐログインして、購入済みのコードを使って視聴をスタートしましょう。はじめてご利用の方はアカウント登録からお願いします。'
                  }
                </p>
                <Spacer level={3} />
                <div className="tw-mx-auto tw-w-full tw-max-w-[30rem] laptop:tw-m-0 laptop:tw-max-w-[20rem]">
                  <div className="">
                    <ButtonLinkPrimary
                      href={`${appPublicConfig.ACCOUNT}/login`}
                      text="ログイン/アカウント登録"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Spacer level={4} />
          <section className="tw-bg-brand-lv3 tw-py-[4rem]">
            <div className="tw-w-full-space tw-mx-auto tw-max-w-[40rem]">
              <h2 className="tw-text-center tw-text-h3 tw-text-brand-lv1">
                HELLO EROとは
              </h2>
              <Spacer level={1} />
              <p>
                HELLO ERO Powered by
                H2Uは、ソフト・オン・デマンド株式会社が運営するアダルト動画配信サービスです。楽しみ方はとてもシンプル。Amazonでお好きな作品の視聴コードを購入し、本サイトからログインして視聴コードを入力するだけ。SOD・FALENO・プレステージをはじめとする400メーカー以上の作品をラインアップし、一部の最新作はDVD発売前の先行配信も。アダルトコンテンツをカジュアルにお楽しみください。
              </p>
              <Spacer level={1.5} />
              <div className="tw-mx-auto tw-grid tw-w-full tw-max-w-[30rem] tw-grid-cols-1 tw-gap-[1rem] laptop:tw-max-w-[40rem] laptop:tw-grid-cols-2">
                <div>
                  <ButtonLinkPrimary
                    href="https://www.amazon.co.jp/b?node=5378643051"
                    text="Amazonで作品を探す"
                    icon="amazon"
                    target="_blank"
                  />
                </div>
                <div>
                  <ButtonLinkSecondary
                    href={appPublicConfig.HELP}
                    text="使い方"
                    icon="help"
                    target="_blank"
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
