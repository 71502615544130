import { useRouter } from 'next/navigation';
import React from 'react';
import { useUserInfo } from '@/shared/hooks/useUserInfo';

export function useSiteTop() {
  const router = useRouter();
  const { isLoadedUserInfo, userInfo } = useUserInfo();
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isLoadedUserInfo) {
      return;
    }

    if (userInfo.isLoggedIn) {
      router.replace('/my-library');
      return;
    }

    setIsInitialized(true);
  }, [isLoadedUserInfo, router, userInfo.isLoggedIn]);

  return {
    isInitialized,
  };
}
